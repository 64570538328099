import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { ReactComponent as Logo } from '../img/Logotipo-PM.svg';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <>
                <div className="header">
                    <Logo />
                </div>
                <Container className="body">
                    {this.props.children}
                </Container>
                <div className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="pm-heading pm-color-blue pm-bold mt-3">Formas de Pagamento </div>
                                <div className="mt-2">
                                    <a className="bandeira-cartao bandeira-cartao-visa"></a>
                                    <a className="bandeira-cartao bandeira-cartao-mastercard"></a>
                                    <a className="bandeira-cartao bandeira-cartao-elo"></a>
                                    <a className="bandeira-cartao bandeira-cartao-amex"></a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="pm-heading pm-color-blue pm-bold mt-3">Certificados</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
