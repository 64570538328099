import React, { Component } from 'react';
import { loadReCaptcha } from 'react-recaptcha-google'
import Captcha from './Captcha';
import { cpfMask, creditCardMask } from './masks/mask-fields'
import { isValidCPF } from './validations/validation-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCreditCard, faLock } from '@fortawesome/free-solid-svg-icons'
import * as cardValidator from 'card-validator'

export class Captura extends Component {
    static displayName = Captura.name;

    constructor(props) {
        super(props);
        this.state = {
            cpf: "",
            token: "",
            bandeira: "Master",
            nomeCliente: "",
            numeroCartao: "",
            validadeMes: "01",
            validadeAno: "",
            cpfTitular: "",
            solicitacaoExiste: false,
            tokenValido: false,
            dadosEnviados: false,
            recaptchaToken: "",
            isLoading: false,
            isInvalidCreditCard: false
        }
        this.verifyCallback = this.verifyCallback.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputChangeCPF = this.handleInputChangeCPF.bind(this)
        this.cardBrandsDictionary = { "visa": "Visa", "amex": "Amex", "american-express": "Amex", "discover": "Discover", "jcb": "JCB", "mastercard": "Master" }
        this.handleInputChangeCreditCard = this.handleInputChangeCreditCard.bind(this)
        
    }

    componentDidMount() {
        loadReCaptcha();
        this.setState({ validadeAno: new Date().getFullYear() });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!!this.state.numeroCartao && this.state.numeroCartao.replace(/\D/g, '') !== (prevState.numeroCartao ?? "").replace(/\D/g, '')) {
            const validationResult = cardValidator.number(this.state.numeroCartao)
            if (!validationResult.isValid) {
                this.setState({ bandeira: "default", isInvalidCreditCard: true })
                return
            }
            this.setState({ bandeira: this.cardBrandsDictionary[validationResult.card.type] ?? "default", isInvalidCreditCard: false })
            //console.log(validationResult)
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({ [name]: value })
    }

    handleInputChangeCPF(event) {
        this.setState({ [event.target.name]: cpfMask(event.target.value) })
    }

    handleInputChangeCreditCard(event) {
        this.setState({ [event.target.name]: creditCardMask(event.target.value) })
    }

    verifyCallback(token) {
        this.setState({ recaptchaToken: token })
    }

    render() {
        let formIdentificacao = this.state.solicitacaoExiste ? (!this.state.dadosEnviados ? this.renderIdentificacao() : <></>) : this.renderIdentificacaoInput();
        let formValidacao = this.state.solicitacaoExiste && !this.state.tokenValido ? this.renderValidacaoInput() : <></>;
        let formDadosCartao = this.state.tokenValido && !this.state.dadosEnviados ? this.renderDadosCartaoInput() : <></>;
        let formAgradecimento = this.state.dadosEnviados ? this.renderAgradecimento() : <></>;

        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col pm-heading">
                                    <FontAwesomeIcon icon={faUser} /> Identificação
                                </div>
                            </div>
                            {formIdentificacao}
                            {formValidacao}
                            {formDadosCartao}
                            {formAgradecimento}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderIdentificacao() {
        return (
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label className="pm-label">CPF do assinante</label>
                        <div className="form-control">{this.state.cpf}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderIdentificacaoInput() {
        return (
            <form className="form-horizontal pb-4">
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label className="pm-label" htmlFor="cpfInput">CPF do assinante<span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="cpfInput" name="cpf" placeholder="999.999.999-99" value={this.state.cpf} onChange={this.handleInputChangeCPF} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Captcha verifyCallback={this.verifyCallback} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button type="button" className="btn btn-primary pm-button" disabled={this.state.cpf.length < 14 || this.state.isLoading} onClick={this.buscaSolicitacaoCaptura}>
                            Continuar
                            {this.state.isLoading &&
                                <div className="spinner-grow float-right" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            }
                        </button>
                    </div>
                </div>
            </form>);
    }

    renderValidacaoInput() {
        return (
            <form className="form-horizontal pb-4">
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label className="pm-label" htmlFor="tokenInput">Código de Acesso<span className="text-danger">*</span></label>
                            <input type="email" className="form-control" id="tokenInput" name="token" placeholder="XXXXXX" maxLength="6" value={this.state.token} onChange={this.handleInputChange} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button type="button" className="btn btn-primary pm-button" disabled={this.state.token.length < 6 || this.state.isLoading} onClick={this.validaToken}>
                            Continuar
                            {this.state.isLoading &&
                                <div className="spinner-grow float-right" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            }
                        </button>
                    </div>
                </div>
            </form>);
    }

    renderDadosCartaoInput() {
        return (
            <form className="form-horizontal pb-4">
                <div className="row">
                    <div className="col pm-heading">
                        <FontAwesomeIcon icon={faCreditCard} /> Pagamento
                    </div>
                    <div className="col">
                        <div className="pm-security-badge">
                            <div>Ambiente Seguro </div><FontAwesomeIcon icon={faLock} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label className="pm-label" htmlFor="numeroCartaoInput">Número do Cartão<span className="text-danger">*</span></label>
                            <div className="input-group has-validation">
                                <input type="text" className={(this.state.numeroCartao ?? "").length > 16 && this.state.isInvalidCreditCard ? "form-control is-invalid" : "form-control"} id="numeroCartaoInput" name="numeroCartao" placeholder="9999 9999 9999 9999" maxLength="19" value={this.state.numeroCartao} onChange={this.handleInputChangeCreditCard} />
                                <div className="invalid-feedback">
                                    Por favor, verifique o número de cartão digitado.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label className="pm-label" htmlFor="bandeiraSelect">Bandeira<span className="text-danger">*</span></label>
                            <select className="form-control" id="bandeiraSelect" name="bandeira" value={this.state.bandeira} onChange={this.handleInputChange}>
                                <option value="default" disabled>Bandeira...</option>
                                <option>Master</option>
                                <option>Visa</option>
                                <option>Amex</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label className="pm-label" htmlFor="validadeMesSelect">Validade<span className="text-danger">*</span></label>
                            <select className="form-control" id="validadeMesSelect" name="validadeMes" value={this.state.validadeMes} onChange={this.handleInputChange}>
                                <option value="default" disabled>Mês de Validade</option>
                                <option>01</option>
                                <option>02</option>
                                <option>03</option>
                                <option>04</option>
                                <option>05</option>
                                <option>06</option>
                                <option>07</option>
                                <option>08</option>
                                <option>09</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                            </select>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label className="pm-label pm-color-transparent" htmlFor="validadeAnoSelect">Validade</label>
                            <select className="form-control" id="validadeAnoSelect" name="validadeAno" value={this.state.validadeAno} onChange={this.handleInputChange}>
                                <option value="default" disabled>Ano de Validade</option>
                                {this.renderAnoValidacao()}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label className="pm-label" htmlFor="nomeInput">Nome impresso no cartão<span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="nomeInput" name="nomeCliente" placeholder="NOME DO CLIENTE" value={this.state.nomeCliente} onChange={this.handleInputChange} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label className="pm-label" htmlFor="cpfTitularInput">CPF do titular do cartão<span className="text-danger">*</span></label>
                            <div className="input-group has-validation">
                                <input type="text" className={(this.state.cpfTitular ?? "").length > 0 && !isValidCPF(this.state.cpfTitular) ? "form-control is-invalid" : "form-control"} id="cpfTitularInput" name="cpfTitular" placeholder="999.999.999-99" value={this.state.cpfTitular} onChange={this.handleInputChangeCPF} />
                                <div className="invalid-feedback">
                                    Por favor, verifique o CPF digitado.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" className="btn btn-primary pm-button" disabled={this.disableFormSubmit(this.state)} onClick={this.enviarDadosCaptura}>
                    Finalizar assinatura
                    {this.state.isLoading &&
                        <div className="spinner-grow float-right" role="status">
                            <span className="sr-only"></span>
                        </div>
                    }
                </button>
            </form>);
    }

    disableFormSubmit(state) {
        return state.isInvalidCreditCard || (state.numeroCartao ?? "").length < 16 || !isValidCPF(state.cpfTitular) || (state.nomeCliente ?? "").length < 3 || !state.validadeAno || !state.validadeMes || state.isLoading
    }

    renderAnoValidacao() {
        let items = [];
        var currentYear = new Date().getFullYear();

        for (let i = 0; i <= 10; i++) {
            items.push(<option key={i}>{currentYear + i}</option>);
        }

        return items;
    }

    renderAgradecimento() {
        return (
            <div id="overlay">
                <div id="overlay-content">
                    <div className="pm-color-blue pm-overlay-heading pm-bold">Dados registrados com sucesso!</div>
                    <p>Por favor, aguarde a confirmação de ativação do plano por e-mail ou SMS.</p>
                    <div>Qualquer dúvida, entre em contato conosco através do número:</div>
                    <div className="pm-bold pm-color-red mb-3">0800 275 1313</div>
                    <div className="text-muted"> Você já pode fechar esta página.</div>
                </div>
            </div>
        );
    }

    buscaSolicitacaoCaptura = async (e) => {
        try {
            e.preventDefault();
            this.setState({ isLoading: true });
            var requestHeaders = new Headers();
            requestHeaders.append("recaptchaToken", this.state.recaptchaToken);

            if (this.state.recaptchaToken) {
                const response = await fetch('captura/buscarsolicitacao/' + this.state.cpf, {
                    method: "GET",
                    headers: requestHeaders
                });
                const data = await response.json();

                if (data.result)
                    this.setState({ solicitacaoExiste: true });
                else {
                    if (data.statusCode === 404)
                        alert("A solicitação não foi encontrada.");
                    else
                        alert("Ocorreu um erro ao buscar a solicitação. Por favor, tente novamente mais tarde.");
                }

            }
            else
                alert("É necessário verificar o Captcha");
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ isLoading: false });
        }

    }

    validaToken = async (e) => {
        try {
            this.setState({ isLoading: true });
            e.preventDefault();

            const response = await fetch('captura/validarToken/' + this.state.cpf + '/' + this.state.token);
            const data = await response.json();

            if (data.result)
                this.setState({ tokenValido: true });
            else
                alert("Não foi possível validar o Token.");
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    enviarDadosCaptura = async (e) => {
        this.setState({ isLoading: true });
        try {
            e.preventDefault();

            var dadosCaptura = {
                CPF: this.state.cpf,
                TokenAuth: parseInt(this.state.token),
                NomeClienteCartao: this.state.nomeCliente,
                NumeroCartao: this.state.numeroCartao.replace(/\D/g, ''),
                Holder: this.state.nomeCliente,
                DataExpiracao: this.state.validadeMes + "/" + this.state.validadeAno,
                Bandeira: this.state.bandeira,
                CPFTitular: this.state.cpfTitular
            }

            const response = await fetch('captura/enviarDadosCaptura', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dadosCaptura)
            });
            const data = await response.json();

            if (data.result)
                this.setState({ dadosEnviados: true });
            else
                alert("Não foi possível enviar os dados do cartão.");
        } catch (e) {

        } finally {
            this.setState({ isLoading: false });
        }

    }
}
