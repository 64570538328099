import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-google'

class Captcha extends Component {
    constructor(props, context) {
        super(props, context);
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    }

    componentDidMount() {
        if (this.captcha) {
            this.captcha.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captcha) {
            this.captcha.reset();
        }
    }

    render() {
        return (
            <div className="div-captcha">
                {window.recaptcha && 
                <ReCaptcha
                    ref={(el) => { this.captcha = el; }}
                    size="normal"
                    data-theme="dark"
                    render="explicit"
                    sitekey="6LepxuMZAAAAAHBHh-hmHpaAqV-esPXEvqpqo296"
                    hl="pt-BR"
                    onloadCallback={this.onLoadRecaptcha}
                    verifyCallback={this.props.verifyCallback}
                />
                }
            </div>
        );
    };
};

export default Captcha;