import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Captura } from './components/Captura';

import './fonts/BunueloCleanPro-Regular.ttf'; 
import './fonts/BunueloCleanPro-Regular.woff'; 
import './fonts/BunueloCleanPro-RegularIt.ttf'; 
import './fonts/BunueloCleanPro-RegularIt.woff'; 
import './fonts/BunueloCleanPro-SemiBold.ttf'; 
import './fonts/BunueloCleanPro-SemiBold.woff'; 
import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Captura} />
      </Layout>
    );
  }
}
